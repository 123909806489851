<template>
  <div>
    <el-page-header @back="dynamic" content="用户配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="outerVisible = true;">新增</el-button>
      </el-button>
      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="form">
          <el-form-item label="用户名" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.empName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth"  class="w-50">
            <el-radio v-model="form.empSexy" label="1">男</el-radio>
            <el-radio v-model="form.empSexy" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="身份证号" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.empNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="部门" :label-width="formLabelWidth"  class="w-50">
            <el-select v-model="form.depId" placeholder="请选择">
              <el-option
                  v-for="item in depData"
                  :key="item.depId"
                  :label="item.depName"
                  :value="item.depId">
                <span style="float: left">{{ item.depName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="岗位" :label-width="formLabelWidth"  class="w-50">
            <el-input v-model="form.empPost" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="入职日期" :label-width="formLabelWidth"  class="w-50">
            <el-date-picker
                v-model="form.empDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-dialog
            width="20%"
            title="警告"
            :visible.sync="innerVisible"
            append-to-body>
          <span>您将新增一条记录，请确认！</span>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="innerVisible = false;outerVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="resetForm(); innerVisible = false; outerVisible = false">确 定</el-button>
          </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" @click="outerVisible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="innerVisible = true">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="userData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="userName"
          label="用户名">
      </el-table-column>
      <el-table-column
          prop="depId"
          label="角色"
          width="120">
        <template slot-scope="scopeo">
          {{ scopeo.row.relo.rname }}
        </template>
      </el-table-column>
      <el-table-column
          prop="empDate"
          label="关联人员"
          width="100">
        <template slot-scope="scopeEmp">
          {{ scopeEmp.row.emp.empName }}
        </template>
      </el-table-column>
      <el-table-column
          prop="lastTime"
          label="登录日期"
          width="160">
      </el-table-column>
      <el-table-column
          prop="open"
          label="状态"
          width="70">
        <template slot-scope="scope">
          <el-switch v-if="(scope.row.open === 1)"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     disabled
                     v-model= "isOpen">
          </el-switch>
          <el-switch v-if="(scope.row.open === 0)"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     disabled
                     v-model= "isClose">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="150">
        <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                style="padding: 2px; margin: 5px">
              <i class="el-icon-delete"></i>
            </el-button>
            <el-button

                style="padding: 2px; margin: 5px 0px">
              <i class="el-icon-edit"></i>
            </el-button>
          <el-button
              @click.native.prevent="addUserMenuChildren(scope.row.userId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-tickets"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        title="菜单编辑"
        :visible.sync="dialog"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        size="40%"
    >
      <div class="demo-drawer__content" style="padding: 0 50px 20px 50px">
        <el-tree
            :data="menuList"
            ref="tree"
            show-checkbox
            node-key="menuId"
            default-expand-all
            :default-checked-keys="menu"
            :props="defaultProps">
        </el-tree>
      </div>
      <div class="demo-drawer__footer" style="margin-left: 50px; margin-bottom: 80px">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="subMenuTree">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "user",
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      isOpen: true,
      isClose: false,
      form: {
        empName: '',
        empSexy: '1',
        empNum: '',
        depId: '',
        empPost: '',
        empDate: ''
        // delivery: false
      },
      formLabelWidth: '120px',
      userData: [],
      depData: [],
      dialog: false,
      defaultProps: {
        children: 'menuChildren',
        label: 'menuName',
      },
      menuList: [],
      menu: [],
      userId: ''
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      this.$http({
        method: 'get',
        url: '/api/user/getUserList',
        data: ''
      }).then((res) =>{
        this.userData = res.data.data
      })
    },
    resetForm() {

    },
    dynamic() {
      this.$router.push("dynamic")
    },
    addUserMenuChildren(userId) {
      this.getMenuList()
      this.menuListById(userId)
      this.userId = userId
    },
    getMenuList() {
      this.$http({
        method: 'get',
        url: '/api/menu/getMenuList'
      }).then((res)=>{
        this.menuList = res.data.data
      })
    },
    menuListById(userId) {
      this.menu = []
      const loading = this.$loading({
        lock: true,
        text: '数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http({
        method: 'get',
        url: '/api/menu/getMenuById/' + userId
      }).then((res)=> {
        this.menu = res.data.data
        if (res.data.status) {
          loading.close()
          this.dialog = true;
        }
      })
    },
    cancelForm(){
      this.dialog = false
    },
    subMenuTree(){
      var mList = []
      var umList = []
      mList = this.$refs.tree.getCheckedKeys();
      for (var i=0; i< mList.length; i++) {
        var um = {
          userId: '',
          menuId: ''
        }
        um.userId = this.userId
        um.menuId = mList[i]
        umList.push(um)
      }
      // console.log(umList)
      this.$confirm('是否修改目录' , '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=> {
        this.$http({
          method: 'post',
          url: '/api/menu/editMenu',
          data: umList
        }).then((res) => {
          if (res.data.status) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.cancelForm()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
      }).catch(()=>{
        this.$message({
          type: 'error',
          message: "取消提交"
        })
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>